
















































































































































































































































import Vue from "vue";
import $ from "jquery";

import AddSeatsModal from "../modals/AddSeatsModal.vue";
import ReduceSeatsModal from "../modals/ReduceSeatsModal.vue";
import ChangeToAnnualPlanModal from "../modals/ChangeToAnnualPlanModal.vue";
import ChangeToMonthlyPlanModal from "../modals/ChangeToMonthlyPlanModal.vue";
import CancelPlanModal from "../modals/CancelPlanModal.vue";
import ManagePlanModal from "../modals/ManagePlanModal.vue";

import {
  IGraphCompany,
  IGraphCompanySubscriptionItemsEdge,
  IGraphCompanySubscriptionNode,
  ISubscription,
  ISubscriptionItem,
} from "../types";
import { asDate } from "../../../filters/common";

export default Vue.extend({
  filters: { asDate },
  components: {
    AddSeatsModal,
    ReduceSeatsModal,
    ChangeToAnnualPlanModal,
    ChangeToMonthlyPlanModal,
    CancelPlanModal,
    ManagePlanModal,
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      subscription: {} as ISubscription,
      subscriptionItem: {} as ISubscriptionItem,
      showAddSeatsModal: false,
      showReduceSeatsModal: false,
      showChangeToAnnual: false,
      showChangeToMonthly: false,
      showCancelPlanModal: false,
      showManagePlanModal: false,
      selectedPlan: {} as ISubscriptionItem,
    };
  },
  created: function () {
    this.fetchData();
  },
  updated: function () {
    // trigger a click event on the first li item in the navTabs element
    // this is needed to set the initial state of the tab.
    const navTabs = this.$refs.navTabs;
    if (navTabs) {
      let firstLi = (navTabs as HTMLElement).querySelector("li");
      if (firstLi) {
        // Create a new MouseEvent object
        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        // Dispatch the click event on the element
        let aTag = firstLi.querySelector("a");
        if (aTag) {
          aTag.dispatchEvent(event);
        }
      }
    }
  },

  methods: {
    addSeats: function (item: ISubscriptionItem) {
      this.subscriptionItem = item;
      this.showAddSeatsModal = true;
    },
    reduceSeats: function (item: ISubscriptionItem) {
      this.subscriptionItem = item;
      this.showReduceSeatsModal = true;
    },
    switchCycle: function () {
      console.log("switch cycle");
      // const plan = this.$store.getters.plans[0];
      // this.selectedPlan = plan;
      if (this.subscription.items[0].billingCycle === "MONTH") {
        this.subscriptionItem = this.subscription.items[0];
        this.showChangeToAnnual = true;
      } else {
        this.subscriptionItem = this.subscription.items[0];
        this.showChangeToMonthly = true;
      }
    },
    renewPlan: function () {
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables: {
            mutateSubscriptionInput: {
              company: this.$route.params.id,
              cancel: true,
              id: this.subscription.id,
            },
          },
          query: `
            mutation cancelPlan (
              $mutateSubscriptionInput: MutateSubscriptionInput!
            ) {
              mutateSubscription(input: $mutateSubscriptionInput) {
                errors { messages }
                subscription {
                  id
                  stripeSubscriptionId
                }
              }
            }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateSubscription?.errors.length > 0) {
            alert(result.data.mutateSubscription.errors[0].messages.join(", "));
          }
          location.reload();
        });
    },
    cancelPlan: function () {
      const plan = this.subscriptionItem;
      this.selectedPlan = plan;
      $("#cancel-plan-modal").modal({ show: true });
    },
    managePlan: function (item: ISubscriptionItem) {
      this.subscriptionItem = item;
      this.showManagePlanModal = true;
    },
    calcNumberOfBuilderSeats: function () {
      return 0;
    },
    fetchData: function () {
      const query = JSON.stringify({
        query: `
          query getCompanySubscriptions {
            company (id: ${this.$route.params.id}) {
              id: contentObjectId
              name
              billingOwner {
                userId
              }
              subscriptions {
                edges {
                  node {
                    id: contentObjectId
                    status
                    startPeriodDate
                    endPeriodDate
                    endFreeTrialDate
                    stripeSubscriptionId
                    company {
                      id: contentObjectId
                    }
                    items {
                      edges {
                        node {
                          id: contentObjectId
                          stripeSubscriptionItemId
                          amount
                          upTo
                          remainingSeats
                          planPrice {
                            billingCycle
                            stripePriceId
                            pricingPlan {
                              id: contentObjectId
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result: IGraphCompany) => {
          this.subscription = this.mapSubscription(result);
        });
    },

    mapSubscription: function (company: IGraphCompany) {
      const subscription: IGraphCompanySubscriptionNode =
        company.data.company.subscriptions.edges[0];

      const result = {
        id: Number(subscription.node.id),
        companyId: company.data.company.id,
        startDate: new Date(subscription.node.startPeriodDate),
        endDate: new Date(subscription.node.endPeriodDate),
        nextBillingDate: new Date(subscription.node.endPeriodDate),
        billingOwnerId: Number(company.data.company.billingOwner.id),
        status: subscription.node.status,
        freeTrialEndDate: new Date(subscription.node.endFreeTrialDate),
        stripeSubscriptionId: subscription.node.stripeSubscriptionId,
        items: this.mapSubscriptionItems(subscription.node.items),
      } as ISubscription;

      return result;
    },

    mapSubscriptionItems: function (
      items: IGraphCompanySubscriptionItemsEdge
    ): Array<ISubscriptionItem> {
      let result = [] as Array<ISubscriptionItem>;

      items.edges.forEach((item) => {
        const newItem = {
          id: Number(item.node.id),
          plan: {
            id: Number(item.node.planPrice.pricingPlan.id),
            name: item.node.planPrice.pricingPlan.name,
          },
          subscriptionId: item.node.stripeSubscriptionItemId,
          priceTierId: Number(item.node.planPrice.pricingPlan.id),
          billingCycle: item.node.planPrice.billingCycle,
          remainingSeats: item.node.remainingSeats,
          amount: Number(item.node.amount),
          upTo: Number(item.node.upTo),
          currency: "USD",
          stripeSubscriptionId: item.node.stripeSubscriptionItemId,
          stripePriceId: item.node.planPrice.stripePriceId,
        } as ISubscriptionItem;
        result.push(newItem);
      });

      return result;
    },
  },
  computed: {
    isBuilderCompany: function (): boolean {
      return this.$store.state.session.isBuilder;
    },
  },
});
