


































































































import Vue from "vue";
import $ from "jquery";
import {
  IGraphCompany,
  IGraphCompanySubscriptionItemsEdge,
  IGraphCompanySubscriptionNode,
  IMember,
  IMemberLicense,
  ISubscription,
  ISubscriptionItem,
  IUserLicense,
} from "../types";

interface IManageLicense {
  id: number; // the user_license id
  planName: string;
  subscriptionItemId: number; // the subscription_item id
  userId: number;
  remainingSeats: number;
  upTo: number;
  originalValue: boolean; // save the original value to compare
  value: boolean; // true if the license is active
}

export default Vue.extend({
  name: "ManageLicense",
  props: {
    // Define your props here
    action: {
      type: String,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data: function () {
    return {
      errors: false,
      errorMessage: "",
      showNoSeatsRemaining: false,
      localLicenses: [] as IMemberLicense[],
      subscription: {} as ISubscription,
      manageLicenses: [] as IManageLicense[],
    };
  },
  mounted: function () {
    this.fetchCompanyData();
  },
  methods: {
    closeModal: function () {
      this.$emit("closed");
    },
    saveChanges: function () {
      console.log("saveChanges");
      this.manageLicenses.forEach((license) => {
        let query = "";
        if (license.value !== license.originalValue) {
          if (!license.value) {
            // deactivate license
            console.log("deactivate", license);
            query = JSON.stringify({
              query: `
                mutation {
                  mutateCompanyUserLicense(input:{id: ${license.id}, userId: ${license.userId}}) {
                    success
                  }
                }
              `,
            });
          } else {
            // activate license
            console.log("activate", license);
            query = JSON.stringify({
              query: `
                mutation {
                  mutateCompanyUserLicense(input:{userId: ${license.userId}, subscriptionItem: ${license.subscriptionItemId}}) {
                    success
                  }
                }
              `,
            });
          }

          fetch("/graphql/", {
            method: "POST",
            body: query,
            headers: {
              "content-type": "application/json",
              Accept: "application/json",
            },
          })
            .then((data) => data.json())
            .then((result) => {
              console.log("result", result);
              if (result.errors) {
                this.errors = true;
                this.errorMessage = result.errors[0].message;
              } else {
                this.$emit("closed");
                this.$router.go(0);
              }
            });
        }
      });
    },
    fetchCompanyData: function () {
      const query = JSON.stringify({
        query: `
          query getCompanySubscriptions {
            company (id: ${this.$route.params.id}) {
              id: contentObjectId
              name
              billingOwner {
                userId
              }
              subscriptions {
                edges {
                  node {
                    id: contentObjectId
                    status
                    startPeriodDate
                    endPeriodDate
                    endFreeTrialDate
                    stripeSubscriptionId
                    company {
                      id: contentObjectId
                    }
                    items {
                      edges {
                        node {
                          id: contentObjectId
                          stripeSubscriptionItemId
                          amount
                          upTo
                          remainingSeats
                          planPrice {
                            billingCycle
                            stripePriceId
                            pricingPlan {
                              id: contentObjectId
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result: IGraphCompany) => {
          this.subscription = this.mapSubscription(result);
          this.subscription.items.forEach((item) => {
            item.remainingSeats === 0
              ? (this.showNoSeatsRemaining = true)
              : (this.showNoSeatsRemaining = false);
          });
        });
    },

    mapSubscription: function (company: IGraphCompany) {
      const subscription: IGraphCompanySubscriptionNode =
        company.data.company.subscriptions.edges[0];

      const result = {
        id: Number(subscription.node.id),
        companyId: company.data.company.id,
        startDate: new Date(subscription.node.startPeriodDate),
        endDate: new Date(subscription.node.endPeriodDate),
        nextBillingDate: new Date(subscription.node.endPeriodDate),
        billingOwnerId: Number(company.data.company.billingOwner.id),
        status: subscription.node.status,
        freeTrialEndDate: new Date(subscription.node.endFreeTrialDate),
        stripeSubscriptionId: subscription.node.stripeSubscriptionId,
        items: this.mapSubscriptionItems(subscription.node.items),
      } as ISubscription;

      return result;
    },

    mapSubscriptionItems: function (
      items: IGraphCompanySubscriptionItemsEdge
    ): Array<ISubscriptionItem> {
      let result = [] as Array<ISubscriptionItem>;

      items.edges.forEach((item) => {
        const newItem = {
          id: Number(item.node.id),
          plan: {
            id: Number(item.node.planPrice.pricingPlan.id),
            name: item.node.planPrice.pricingPlan.name,
          },
          subscriptionId: item.node.stripeSubscriptionItemId,
          priceTierId: Number(item.node.planPrice.pricingPlan.id),
          billingCycle: item.node.planPrice.billingCycle,
          remainingSeats: item.node.remainingSeats,
          amount: Number(item.node.amount),
          upTo: Number(item.node.upTo),
          currency: "USD",
          stripeSubscriptionId: item.node.stripeSubscriptionItemId,
          stripePriceId: item.node.planPrice.stripePriceId,
        } as ISubscriptionItem;
        result.push(newItem);
      });

      return result;
    },

    userHasLicense: function (item: ISubscriptionItem): boolean {
      const hasMatchingLicense = this.member.companyLicenses.some(
        (license: IMemberLicense) => {
          const subscriptionItemId = Number(license.subscriptionItem.id);
          return item.id === subscriptionItemId;
        }
      );
      return hasMatchingLicense;
    },

    getMemberLicenseIndex: function (item: ISubscriptionItem): number {
      return this.member.companyLicenses.findIndex(
        (license: IMemberLicense) => {
          const subscriptionItemId = Number(license.subscriptionItem.id);
          return item.id === subscriptionItemId;
        }
      );
    },
  },
  computed: {
    // Define your computed properties here
  },
  watch: {
    showModal: function (newValue: boolean) {
      if (newValue) {
        $("#manageLicenseModal").modal("show");
      } else {
        $("#manageLicenseModal").modal("hide");
      }
    },
    member: {
      handler: function (newValue: IMember) {
        console.log("licenses", newValue);
        this.localLicenses = newValue.companyLicenses;
        this.manageLicenses = [];
        if (this.subscription.items.length === 0) {
          return;
        }
        this.subscription.items.forEach((item) => {
          console.log("item", item);
          this.manageLicenses.push({
            id: this.userHasLicense(item)
              ? newValue.companyLicenses[this.getMemberLicenseIndex(item)].id
              : 0, // the user_license id
            planName: item.plan.name,
            subscriptionItemId: item.id,
            userId: newValue.id,
            remainingSeats: item.remainingSeats,
            upTo: item.upTo,
            originalValue: this.userHasLicense(item),
            value: this.userHasLicense(item),
          });
        });

        // this.localLicenses = newValue.map((license) => {
        //   return {
        //     ...license,
        //     active: true,
        //   };
        // });
      },
      deep: true,
    },
  },
});
