























import Vue from "vue";

export default Vue.extend({
  props: {
    companyId: {
      type: Number,
    },
  },
  components: {},
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
  watch: {},
});
