












































































































































































































































































































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { PropType } from "vue";

import {
  ICompany,
  IMember,
  IMemberLicense,
  IPlanAndTiers,
  IPriceTier,
} from "../types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { StripeAddressElement } from "@stripe/stripe-js";

export default Vue.extend({
  components: {},
  props: {
    members: {
      type: Array as PropType<IMember[]>,
      required: true,
    },
    selectedPlanAndTier: {
      type: Object as PropType<IPlanAndTiers>,
    },
    billingOwnerId: {
      type: String,
    },
    companyName: {
      type: String,
    },
    stripeId: {
      type: String,
    },
    companyIndex: {
      type: Number,
    },
  },
  data() {
    return {
      newCompanyName: this.companyName,
      company: {} as ICompany,
      newCompanyCountryCode: "",
      newCompanyState: "",
      newCompanyBillingMethod: "",
      selectedWebTierId: [] as number[],
      selectedMobileTierId: [] as number[],
      licensingMember: {} as IMember,
      addressElement: {} as StripeAddressElement,
      isAddressValid: false,
      migrationStarted: false,
    };
  },
  mounted: async function () {
    if (this.$store.getters.stripe) {
      // have to use any type on next line because Stripe's typescript support
      // is missing this type
      const appearance = {
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily:
              "'Suisse', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            colorText: "#333333",
            fontSizeBase: "13px",
            fontSizeSm: "13px",
            fontWeightNormal: 500,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      const elements = this.$store.getters.stripe.elements(appearance);

      //const googleAPI = process.env.VUE_APP_GOOGLE_MAPS_KEY as string;

      if (elements) {
        // Create and mount the Address Element in shipping mode
        this.addressElement = elements.create("address", {
          mode: "billing",
          display: {
            name: "organization",
          },
          // autocomplete: {
          //   mode: "google_maps_api",
          //   apiKey: googleAPI,
          // },
          defaultValues: {
            name: this.companyName,
            address: {
              line1: "",
              line2: "",
              city: "",
              state: "",
              postal_code: "",
              country: "",
            },
          },
        });

        this.addressElement.mount("#address-element");
        this.addressElement.on("change", (event) => {
          this.isAddressValid = event.complete;
          //update the company info
          console.log("Address is valid: ");
          console.log(event);
          this.company = {
            id: 0,
            customerNo: "",
            canEdit: false,
            phone: "",
            discountCodeId: 0,
            isSalesCompany: false,
            isWhiteLabelCompany: false,
            isOnlineBillingCompany: false,
            billingOwnerId: "",
            name: event.value.name,
            street1: event.value.address.line1,
            street2: event.value.address.line2,
            city: event.value.address.city,
            postalCode: event.value.address.postal_code,
            state: event.value.address.state,
            countryCode: event.value.address.country,
          };
          console.log("addressElement", this.addressElement);
        });
      } else {
        console.error("Stripe elements not found");
      }
    } else {
      console.error("Stripe not found");
    }
    if (this.stripeId) {
      this.newCompanyBillingMethod = "online";
    } else {
      this.newCompanyBillingMethod = "offline";
    }
    this.initializeLicensingMember();
  },
  methods: {
    initializeLicensingMember: function () {
      // find the first user or the billing owner if the billing owner has a active license
      let foundMember = {} as IMember;

      this.membersToMigrate.forEach((member) => {
        if (Number(member.id) === Number(this.billingOwnerId)) {
          if (member.companyLicenses.length > 0) {
            foundMember = member;
          }
        }
      });

      this.licensingMember = foundMember;
    },
    startMigration: function () {
      this.migrationStarted = true;
      this.$emit(
        "migrateButtonClicked",
        this.newCompany,
        this.membersToMigrate,
        this.newCompanyBillingMethod,
        this.subscriptionItems,
        this.newSubscriptionBillingCycle,
        this.licensingMember.companyLicenses[0].endDate
      );
    },
    formatRole(role: string) {
      const roles = new Map([
        ["BILLING_OWNER", "Billing Owner"],
        ["ADMIN", "Admin"],
        ["MEMBER", "Member"],
      ]);
      return roles.get(role);
    },
    /**
     * formats license type and billing cycle for a user license
     * @param {string} type - unformatted license type string
     * @param {string} cycle - unformatted billing cycle string
     * @returns {string} - a formatted string containing both license type and billing cycle
     */
    formatLicenseTypeAndCycle(member: IMember): string {
      let resultStr = "";
      if (member.companyLicenses.length === 0) {
        resultStr = "No license";
      } else {
        member.companyLicenses.forEach((license) => {
          if (new Date(license.endDate) > new Date()) {
            const formattedType = license.type === "O" ? "Web" : "Mobile";
            const formattedCycle =
              license.billingCycle === "M" ? "Monthly" : "Yearly";
            resultStr += formattedType.concat(" | ", formattedCycle, "<br>");
          } else {
            // resultStr = "No license";
          }
        });
      }
      return resultStr;
    },
    /**
     * converts a license type to a plan type
     * @param {string} type - license type that we wish to convert (either "O" or "M")
     * @returns {string} - corresponding plan type ("O" => "WEB", "M" => "MOBILE")
     */
    toPlanType(type: string): string {
      if (type === "O") {
        return "WEB";
      } else if (type === "M") {
        return "MOBILE";
      }
      return "";
    },
    /**
     * finds number of unmigrated company users for a given license type
     * @param {string} licenseType - license type (either "O" or "M")
     * @returns {number} - number of users for the license type
     */
    userCountForType(licenseType: string): number {
      const localMembers = this.membersToMigrate as IMember[];
      return localMembers.filter((member) => {
        return (
          member.companyLicenses.filter((companyLicense) => {
            return companyLicense.type === licenseType;
          }).length > 0
        );
      }).length;
    },
    /**
     * formats price tier information into a readable string
     * @param {IPriceTier} priceTier - the price tier we are formatting
     * @param {string} licenseType - the license type which corresponds with the tiers plan type
     * @returns {string} - a formatted string
     */
    tierToString(
      selectedPlanAndTier: IPlanAndTiers,
      licenseType: string
    ): string {
      // const pricePlanName = this.$store.getters.pricePlanById(
      //   priceTier.pricePlanId
      // ).name;
      const activeUserCount = this.userCountForType(licenseType);
      // return pricePlanName.concat(
      //   " | ",
      //   priceTier.price,
      //   "/",
      //   priceTier.billingCycle.toLowerCase(),
      //   " | ",
      //   activeUserCount,
      //   " of ",
      //   priceTier.numberOfUsers,
      //   " users."
      // );
      return `${selectedPlanAndTier.name} |
              ${selectedPlanAndTier.tiers[0].amount} /
              ${selectedPlanAndTier.tiers[0].cycle.toLowerCase()} |
              ${activeUserCount} of ${
        selectedPlanAndTier.tiers[0].upTo
      } users.`;
    },
  },
  computed: {
    membersWithCompanyLicense: function () {
      return this.members.filter((member) => {
        return member.companyLicenses.length > 0;
      });
    },
    /**
     * return an array of IMembers that have a license to be migrated
     * a license will be migrated if the member is the billing owner
     * or the user license's end date is in the future.
     */
    membersToMigrate: function (): Array<IMember> {
      return this.members.filter((member) => {
        if (Number(member.id) === Number(this.billingOwnerId)) {
          return true;
        } else {
          if (member.companyLicenses.length > 0) {
            // check all licenses to see if there is an active one
            let result = false;
            for (let i = 0; i < member.companyLicenses.length; i++) {
              if (new Date(member.companyLicenses[i].endDate) >= new Date()) {
                result = true;
                break;
              }
            }
            return result;
          } else {
            return false;
          }
        }
      });
    },
    /**
     * return an array of IMembers that are not going to be migrated
     * a license will not be migrated unless the member is the billing owner
     * or the user license's end date is in the future.
     */
    membersNotBeingMigrated: function (): Array<IMember> {
      return this.members.filter((member) => {
        if (Number(member.id) === Number(this.billingOwnerId)) {
          return false;
        } else {
          if (member.companyLicenses.length > 0) {
            // check all licenses to see if there is an active one, if there is then return false
            let result = false;
            for (let i = 0; i < member.companyLicenses.length; i++) {
              if (new Date(member.companyLicenses[i].endDate) < new Date()) {
                result = false;
                break;
              }
            }
            return result;
          } else {
            return true;
          }
        }
      });
    },
    /**
     * new company object based on user inputs
     * @returns {ICompany} - new company with name, country, state input from user
     */
    newCompany(): ICompany {
      return this.company;
    },
    /**
     * ensure that exactly 1 billing owner is selected
     * @returns {boolean} - true if exactly 1 billing owner is selected
     */
    billingOwnerIsSelected(): boolean {
      const members = this.members as IMember[];
      const billingOwner = members.filter((member) => {
        return member.role === "BILLING_OWNER";
      });
      if (billingOwner.length === 1) {
        return true;
      }
      return false;
    },
    /**
     * checks that the fields needed to migrate a company are valid (depending on the billing method of the new company)
     * @returns {boolean} - true if migration requirements are satisfied
     */
    requiredFieldsAreValid(): boolean {
      let result = false;
      if (this.newCompanyBillingMethod === "offline") {
        if (
          // migration requirements for offline companies
          this.newCompany.name &&
          this.isAddressValid &&
          this.billingOwnerIsSelected
        ) {
          result = true;
        }

        // if the user hasn't selected a plan then disable Migrate button
        if (!this.selectedPlanAndTier) {
          result = false;
        }

        // if we don't have a licensingMember selected then disable Migrate button
        if (this.licensingMember) {
          if (Object.keys(this.licensingMember).length === 0) {
            result = false;
          }
        } else {
          result = false;
        }
      } else if (this.newCompanyBillingMethod === "online") {
        if (
          // migration requirements for online companies
          this.newCompany.name &&
          this.isAddressValid &&
          this.billingOwnerIsSelected
        ) {
          result = true;
        }

        // if the user hasn't selected a plan then disable Migrate button
        if (!this.selectedPlanAndTier) {
          result = false;
        }

        // if we don't have a licensingMember then disable Migrate button
        if (this.licensingMember) {
          if (Object.keys(this.licensingMember).length === 0) {
            result = false;
          }
        } else {
          result = false;
        }
      }
      return result;
    },
    /**
     * license types for company members
     * @returns {string[]} - array of license types (values can only be "O" or "M")
     */
    licenseTypes(): IMemberLicense[] {
      const localMembers = this.members;
      let resultLicenseTypes = new Set<IMemberLicense>();
      let result = [] as Array<IMemberLicense>;
      for (let i = 0; i < localMembers.length; i++) {
        for (let j = 0; j < localMembers[i].companyLicenses.length; j++) {
          const found = result.findIndex((item) => {
            return item.type === localMembers[i].companyLicenses[j].type;
          });
          if (found === -1) {
            result.push(localMembers[i].companyLicenses[j]);
          }
          resultLicenseTypes.add(localMembers[i].companyLicenses[j]);
        }
      }

      return result;
    },
    /**
     * web tier to be applied to selected company
     * @returns {IPriceTier} - price tier that will be applied for web plan, if relevant
     */
    selectedWebTier(): IPriceTier {
      return this.$store.getters.priceTierById(this.selectedWebTierId[0]);
    },
    /**
     * mobile tier to be applied to selected company
     * @returns {IPriceTier} - price tier that will be applied for mobile plan, if relevant
     */
    selectedMobileTier(): IPriceTier {
      return this.$store.getters.priceTierById(this.selectedMobileTierId[0]);
    },
    /**
     * checks that company name is not empty
     * @returns {boolean} - true if company name is not an empty string
     */
    companyNameIsValid(): boolean {
      let result = false;
      if (this.newCompanyName) {
        result = true;
      }
      return result;
    },
    /**
     * JSON string used in backend mutation for creating a subscription
     * @returns {string} - JSON string with subscription info
     */
    subscriptionItems(): Array<{ price: string; quantity: number }> {
      const results: Array<{ price: string; quantity: number }> = [];

      this.members.forEach((member) => {
        member.companyLicenses.forEach((license) => {
          if (new Date(license.endDate) > new Date()) {
            // this is an active license so add to the results
            if (license.selectedPlanAndTier) {
              const newResult = {
                price: license.selectedPlanAndTier.tiers[0].stripeId,
                quantity: license.selectedPlanAndTier.tiers[0].upTo,
              };

              // Check if the newResult already exists in the results array
              const alreadyExists = results.some(
                (result) =>
                  result.price === newResult.price &&
                  result.quantity === newResult.quantity
              );

              if (!alreadyExists) {
                results.push(newResult);
              }
            }
          }
        });
      });

      // if (this.selectedWebTier) {
      //   results.push({
      //     price: this.selectedWebTier.stripePriceId,
      //     quantity: this.selectedWebTier.numberOfUsers,
      //   });
      // }
      // if (this.selectedMobileTier) {
      //   results.push({
      //     price: this.selectedMobileTier.stripePriceId,
      //     quantity: this.selectedMobileTier.numberOfUsers,
      //   });
      // }
      return results;
    },
    /**
     * billing cycle for new subscription
     * @returns {string} - billing cycle ("MONTH" or "YEAR") if billing cycles for all subscription items are the same, else returns an empty string
     */
    newSubscriptionBillingCycle(): string {
      return this.selectedPlanAndTier.tiers[0].cycle;
      // if (this.selectedMobileTierId.length && this.selectedWebTierId.length) {
      //   if (
      //     this.selectedMobileTier.billingCycle ===
      //     this.selectedWebTier.billingCycle
      //   ) {
      //     return this.selectedWebTier.billingCycle;
      //   } else {
      //     return "";
      //   }
      // } else if (this.selectedMobileTierId.length) {
      //   return this.selectedMobileTier.billingCycle;
      // } else if (this.selectedWebTierId.length) {
      //   return this.selectedWebTier.billingCycle;
      // }
      // return "";
    },
  },
  watch: {
    selectedPlanAndTier: function (newValue) {
      console.log("new tier....");
      console.log(newValue);
    },
    members: function () {
      this.initializeLicensingMember();
    },
    "selectedWebTierId.length"(newValue) {
      // if a new tier id is pushed, the old one must be removed
      if (newValue > 1) {
        this.selectedWebTierId = this.selectedWebTierId.slice(-1);
      }
    },
    "selectedMobileTierId.length"(newValue) {
      // if a new tier id is pushed, the old one must be removed
      if (newValue > 1) {
        this.selectedMobileTierId = this.selectedMobileTierId.slice(-1);
      }
    },
    "newCompany.countryCode"(_newValue, oldValue) {
      if (oldValue !== "") {
        this.newCompany.state = "";
      }
    },
    licensingMember: function (newValue) {
      this.$emit("licensingMemberUpdated", newValue);
    },
  },
});
