

































































































import Vue from "vue";
import $ from "jquery";

interface ISubscriptionItem {
  id: number;
  name: string;
  stripeSubscriptionItemId: string;
  remainingSeats: number;
}

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      email: "",
      role: "MEMBER",
      planId: 0,
      emailValid: false,
      errors: false,
      errorMessage: "",
      subscriptionItems: [] as Array<ISubscriptionItem>,
      showNoSeatsRemaining: false,
      companyName: "",
    };
  },
  methods: {
    closeModal: function () {
      this.$emit("closed");
    },
    validateEmail: function () {
      const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      reg.test(this.email)
        ? (this.emailValid = true)
        : (this.emailValid = false);
    },
    sendInvites: function () {
      // map the member role as there is a inconsistency in the backend between company_role and
      // pending invites
      let userRole = "";
      switch (this.role.toLowerCase()) {
        case "admin":
          userRole = "ADMIN";
          break;
        case "member":
          userRole = "MEMBER";
          break;
        case "billing_owner":
          userRole = "BILLING_OWNER";
          break;
        default:
          userRole = "MEMBER";
      }
      let query = "";
      query = JSON.stringify({
        query: `
          mutation send_invites {
            mutatePendingInvite (input : {
              company: ${this.companyId},
              email: "${this.email}",
              role: "${userRole}",
              subscriptionItem: ${this.planId}
            }) {
              errors { messages }
              pendingInvite {
                email
                role
              }
            }
          }`,
      });
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.errors) {
            this.errors = true;
            this.errorMessage = data.errors[0].message;
          } else if (data.data.mutatePendingInvite?.errors.length > 0) {
            this.errors = true;
            this.errorMessage =
              data.data.mutatePendingInvite.errors[0].messages.join(", ");
          } else {
            this.closeModal();
            window.location.href = "/company/" + this.companyId + "/members/";
          }
        });
    },
    numberOfSeatsRemaining: function (itemId: number): number {
      const subscriptionItem = this.subscriptionItems.find((item) => {
        return item.id === itemId;
      });
      if (subscriptionItem) {
        return subscriptionItem.remainingSeats;
      } else {
        return 0;
      }
    },
    fetchData: function () {
      const query = JSON.stringify({
        query: `query getSubscriptionInfo {
          company(id:${this.companyId}){
            name
            subscriptions {
              edges {
                node {
                  items {
                    edges {
                      node {
                        id: contentObjectId
                        stripeSubscriptionItemId
                        planPrice {
                          pricingPlan {
                            name
                          }
                        }
                        remainingSeats
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          this.subscriptionItems = [];

          this.companyName = data.data.company.name;
          data.data.company.subscriptions.edges[0].node.items.edges.forEach(
            (item: {
              node: {
                id: number;
                stripeSubscriptionItemId: string;
                remainingSeats: number;
                planPrice: { pricingPlan: { name: string } };
              };
            }) => {
              this.subscriptionItems.push({
                id: item.node.id,
                stripeSubscriptionItemId: item.node.stripeSubscriptionItemId,
                name: item.node.planPrice.pricingPlan.name,
                remainingSeats: item.node.remainingSeats,
              });
            }
          );
          this.planId = this.subscriptionItems[0].id;
        });
    },
  },
  watch: {
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchData();
        $("#inviteMemberModal").modal("show");
      } else {
        $("#inviteMemberModal").modal("hide");
      }
    },
    planId: function (newValue) {
      if (this.planId) {
        if (this.numberOfSeatsRemaining(newValue) < 1) {
          this.showNoSeatsRemaining = true;
        } else {
          this.showNoSeatsRemaining = false;
        }
      } else {
        this.showNoSeatsRemaining = false;
      }
    },
  },
});
