






































































































































































































import Vue from "vue";

import OrganizationCell from "./OrganizationCell.vue";
import ProjectCell from "./ProjectCell.vue";
import StatesCell from "./StatesCell.vue";
import ProgressCell from "./ProgressCell.vue";
import SignsCell from "./SignsCell.vue";
import StartDateCell from "./StartDateCell.vue";
import EndDateCell from "./EndDateCell.vue";

import {
  IDashboardQL,
  IDashboardQLOrgUser,
  IDashboardRow,
  IDashboardCompany,
  IDashboardCompanyGroup,
  IDashboardProject,
  IDashboardState,
  IDashboardQLProject,
  IDashboardQLState,
} from "../types";

export default Vue.extend({
  components: {
    OrganizationCell,
    ProjectCell,
    StatesCell,
    ProgressCell,
    SignsCell,
    StartDateCell,
    EndDateCell,
  },
  props: {
    projectType: {
      type: String,
      default: "all",
    },
  },
  data: function () {
    return {
      companies: [] as Array<IDashboardCompany>,
      organizations: [] as Array<IDashboardRow>,
      sortColumn: "project",
      sortDirection: "asc",
      searchText: "",
      oldSearchText: "",
      filter: "",
      filterOptions: [
        { value: "", label: "No filters" },
        { value: "favorite", label: "Favorites" },
        { value: "active", label: "Active" },
        { value: "overdue", label: "Overdue" },
        { value: "activeoverdue", label: "Active/Overdue" },
        { value: "archived", label: "Archived" },
      ],
      tableHeight: 300,
      showProcessing: false,
      hasNextPage: false,
      totalProjects: 0,
      endCursor: "",
      saveFailed: false,
      saveErrorMessage: "",
    };
  },
  mounted: function () {
    this.fetchData();
    window.addEventListener("resize", this.resizeTable);
    this.resizeTable();
  },
  methods: {
    userScrolled: function () {
      const el = document.getElementById("dashboard-table-body");
      if (this.hasNextPage) {
        if (el) {
          if (el.scrollTop * 2 >= el.scrollHeight) {
            // turn off next page so that this.fetchData doesn't get called multiple times
            // it will reset by fetchData once the data is retrieved
            this.hasNextPage = false;
            this.fetchData();
          }
        }
      }
    },
    updateStartDate: function (stateId: string, newDate: Date) {
      this.organizations.forEach((organization) => {
        organization.project.states.forEach((state) => {
          if (state.id === stateId) {
            state.startDate = new Date(newDate);
          }
        });
      });
    },
    updateEndDate: function (stateId: string, newDate: Date) {
      this.organizations.forEach((organization) => {
        organization.project.states.forEach((state) => {
          if (state.id === stateId) {
            state.endDate = new Date(newDate);
          }
        });
      });
    },
    showError: function (message: string) {
      this.saveFailed = true;
      this.saveErrorMessage = message;
    },
    resizeTable: function () {
      this.tableHeight = window.innerHeight - 150;
    },
    sortByOrganization: function () {
      if (this.sortColumn === "organization") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "organization";
        this.sortDirection = "asc";
      }
    },
    sortByProject: function () {
      if (this.sortColumn === "project") {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "project";
        this.sortDirection = "asc";
      }
    },
    sortByStartDate: function () {
      if (this.sortColumn === "startDate") {
        // we are already on the start date column so reverse the direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "startDate";
        this.sortDirection = "asc";
      }
    },
    sortByEndDate: function () {
      if (this.sortColumn === "endDate") {
        // we are already on the start date column so reverse the direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = "endDate";
        this.sortDirection = "asc";
      }
    },
    reformatData: function (
      orgId: string,
      orgName: string,
      companies: Array<IDashboardQLOrgUser>,
      project: IDashboardQLProject
    ): IDashboardRow {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      companies.forEach(({ node }) => {
        const user = node.user;
        let companyKey = "";
        if (user.company) {
          companyKey = user.company.id;
        } else {
          companyKey = "-1";
        }

        let company = companiesMap.get(companyKey);

        if (!company) {
          if (companyKey === "-1") {
            company = {
              id: "-1",
              name: "None",
              groups: [],
            };
          } else {
            company = {
              id: user.company.id,
              name: user.company.name,
              groups: [],
            };
          }
          companiesMap.set(companyKey, company);
        }

        const group: IDashboardCompanyGroup = {
          id: node.group.id,
          name: node.group.name,
          users: [
            {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            },
          ],
        };

        // Check if group already exists for this company
        const existingGroup = company.groups.find((g) => g.name === group.name);
        if (existingGroup) {
          existingGroup.users.push({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            email: user.email,
          });
        } else {
          company.groups.push(group);
        }
      });

      const resultProject = this.reformatProjectData(project);
      const resultCompanies: IDashboardCompany[] = Array.from(
        companiesMap.values()
      );

      return {
        id: orgId,
        name: orgName,
        companies: resultCompanies,
        project: resultProject,
      };
    },
    reformatProjectData: function (
      project: IDashboardQLProject
    ): IDashboardProject {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      if (project.node.projectUsers) {
        project.node.projectUsers.edges.forEach((projectUser) => {
          const user = projectUser.node.user;
          let companyKey = "";
          if (user.company) {
            companyKey = user.company.id;
          } else {
            companyKey = "-1";
          }

          let company = companiesMap.get(companyKey);

          if (!company) {
            if (companyKey === "-1") {
              company = {
                id: "-1",
                name: "None",
                groups: [],
              };
            } else {
              company = {
                id: user.company.id,
                name: user.company.name,
                groups: [],
              };
            }
            companiesMap.set(companyKey, company);
          }

          const group: IDashboardCompanyGroup = {
            id: projectUser.node.group.id,
            name: projectUser.node.group.name,
            users: [
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                image: user.image,
                email: user.email,
              },
            ],
          };

          // Check if group already exists for this company
          const existingGroup = company.groups.find(
            (g) => g.name === group.name
          );
          if (existingGroup) {
            existingGroup.users.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            });
          } else {
            company.groups.push(group);
          }
        });
      }

      const companies: IDashboardCompany[] = Array.from(companiesMap.values());

      let states = [] as Array<IDashboardState>;
      project.node.states.edges.forEach((state) => {
        states.push(this.reformatStateData(state));
      });

      return {
        id: project.node.id,
        name: project.node.name,
        type: project.node.phaseType.substring(2),
        canEdit: project.node.canEdit,
        archived: project.node.archived,
        favoriteUsers: project.node.favoriteUsers.edges.map((user) => {
          return { userId: user.node.userId };
        }),
        companies: companies,
        states: states,
      };
    },
    reformatStateData: function (state: IDashboardQLState): IDashboardState {
      const companiesMap: Map<string, IDashboardCompany> = new Map();

      if (state.node.stateUsers) {
        state.node.stateUsers.edges.forEach((stateUser) => {
          const user = stateUser.node.user;
          let companyKey = "";
          if (user.company) {
            companyKey = user.company.id;
          } else {
            companyKey = "-1";
          }

          let company = companiesMap.get(companyKey);

          if (!company) {
            if (companyKey === "-1") {
              company = {
                id: "-1",
                name: "None",
                groups: [],
              };
            } else {
              company = {
                id: user.company.id,
                name: user.company.name,
                groups: [],
              };
            }
            companiesMap.set(companyKey, company);
          }

          const group: IDashboardCompanyGroup = {
            id: stateUser.node.group.id,
            name: stateUser.node.group.name,
            users: [
              {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                image: user.image,
                email: user.email,
              },
            ],
          };

          // Check if group already exists for this company
          const existingGroup = company.groups.find(
            (g) => g.name === group.name
          );
          if (existingGroup) {
            existingGroup.users.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              image: user.image,
              email: user.email,
            });
          } else {
            company.groups.push(group);
          }
        });
      }

      const companies: IDashboardCompany[] = Array.from(companiesMap.values());

      return {
        id: state.node.id,
        name: state.node.name,
        type: state.node.stateType.substring(2),
        archived: state.node.archived,
        startDate: state.node.startDate ? new Date(state.node.startDate) : null,
        endDate: state.node.endDate ? new Date(state.node.endDate) : null,
        canEdit: state.node.canEdit,
        workflow: state.node.workflow.id,
        hexColor: state.node.hexColor,
        approved: state.node.approved,
        rejected: state.node.rejected,
        needsReviewed: state.node.needsReviewed,
        orderId: state.node.orderId,
        signs: state.node.signs.edges.map((sign) => {
          return {
            id: sign.node.id,
            reviewState: sign.node.reviewState,
          };
        }),
        companies: companies,
      };
    },
    buildOrderByString: function (): string {
      let result = "";
      if (this.sortDirection === "desc") {
        result = "-";
      }

      switch (this.sortColumn.toLowerCase()) {
        case "project":
          result += "name";
          break;
        case "organization":
          result += "organization_name";
          break;
      }
      return result;
    },
    freshFetchData: function () {
      this.organizations = [];
      this.hasNextPage = false;
      this.endCursor = "";
      this.oldSearchText = "";
      // then fetch the data
      this.fetchData();
    },
    fetchData: function () {
      this.showProcessing = true;
      const projectSelector =
        this.projectType === "all"
          ? ""
          : `, projectType_In: ${this.projectType}`;
      const orderBy = this.buildOrderByString();
      const nameContains =
        this.searchText !== "" ? `, name_In: "${this.searchText}"` : "";
      const query = JSON.stringify({
        query: `query getProjects {
          projects (first: 10 ${projectSelector}, after: "${this.endCursor}", orderBy: "${orderBy}" ${nameContains}) {
              pageInfo {
                hasNextPage
                endCursor
              }
              totalCount
              edges {
                node {
                  id: contentObjectId
                  name
                  phaseType
                  canEdit
                  archived
                  organization:project {
                    id:contentObjectId
                    name
                    orgUsers:groupUsers (groupNames:["admin"]) {
                      edges {
                        node {
                          group{
                            id: contentObjectId
                            name
                          }
                          user {
                            id: contentObjectId
                            firstName
                            lastName
                            image
                            email
                            company {
                              id: contentObjectId
                              name
                            }
                          }
                        }
                      }
                    }

                  }
                  favoriteUsers {
                    edges {
                      node {
                        userId
                      }
                    }
                  }
                  projectUsers: groupUsers(groupNames: ["member"]) {
                    edges {
                      node {
                        group{
                          id: contentObjectId
                          name
                        }
                        user {
                          id: contentObjectId
                          firstName
                          lastName
                          image
                          email
                          company {
                            id: contentObjectId
                            name
                          }
                        }
                      }
                    }
                  }
                  states {
                    edges {
                      node {
                        id: contentObjectId
                        name
                        archived
                        startDate
                        endDate
                        stateType
                        canEdit
                        workflow {
                          id: contentObjectId
                        }
                        hexColor
                        approved
                        rejected
                        needsReviewed
                        orderId
                        signs {
                          edges {
                            node {
                              id: contentObjectId
                              reviewState
                            }
                          }
                        }
                        stateUsers: groupUsers(groupNames: ["assigned"]) {
                          edges {
                            node {
                              group{
                                id: contentObjectId
                                name
                              }
                              user {
                                id: contentObjectId
                                firstName
                                lastName
                                image
                                email
                                company {
                                  id: contentObjectId
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result: IDashboardQL) => {
          this.hasNextPage = result.data.projects.pageInfo.hasNextPage;
          this.endCursor = result.data.projects.pageInfo.endCursor;
          this.totalProjects = result.data.projects.totalCount;

          result.data.projects.edges.forEach((project) => {
            let newOrg = {
              id: project.node.organization.id,
              name: project.node.organization.name,
              companies: [],
              project: {} as IDashboardProject,
            } as IDashboardRow;

            newOrg = this.reformatData(
              project.node.organization.id,
              project.node.organization.name,
              project.node.organization.orgUsers
                ? project.node.organization.orgUsers.edges
                : [],
              project
            );
            // if this is a design project, only show it to users who are designers
            if (project.node.phaseType === "A_0") {
              if (this.$store.state.session.isDesigner) {
                this.organizations.push(newOrg);
              }
            } else {
              // this isn't a design project so we are good to add it
              this.organizations.push(newOrg);
            }
          });
          this.$nextTick(() => {
            if (this.visibleOrganizations.length < 10) {
              if (this.hasNextPage) {
                this.fetchData();
              }
            } else {
              const el = document.getElementById("dashboard-table-body");
              if (el) {
                el.addEventListener("scroll", this.userScrolled);
              }
            }
          });
          this.showProcessing = false;
        });
    },
  },
  computed: {
    filteredProjects: function (): Array<IDashboardRow> {
      let filteredData = this.organizations;

      // switch (this.projectType) {
      //   case "all":
      //     break;
      //   default: {
      //     filteredData = this.organizations.filter((organization) => {
      //       return organization.project.type === this.projectType;
      //     });
      //   }
      // }

      if (this.searchText === "") {
        if (this.oldSearchText !== "") {
          this.freshFetchData();
        }
        return filteredData;
      } else {
        if (this.hasNextPage) {
          if (this.searchText === this.oldSearchText) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.endCursor = "";
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.hasNextPage = false;
            this.fetchData();
          } else {
            this.freshFetchData();
          }
          return filteredData;
        } else {
          return filteredData.filter((organization) => {
            return (
              organization.project.name.includes(this.searchText) ||
              organization.name.includes(this.searchText)
            );
          });
        }
      }
    },
    visibleOrganizations: function (): Array<IDashboardRow> {
      let filteredOrgs = [] as Array<IDashboardRow>;
      if (this.filter === "archived") {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return organization.project.archived;
        });
      } else {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return !organization.project.archived;
        });
      }

      if (this.filter === "favorite") {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return organization.project.favoriteUsers.some((user) => {
            return user.userId === this.$store.state.session.userId;
          });
        });
      }

      const isActive = function (state: IDashboardState): boolean {
        const today = new Date();
        if (state.startDate) {
          return (
            state.startDate <= today &&
            (!state.endDate || state.endDate >= today)
          );
        } else {
          return false;
        }
      };

      const isOverDue = function (state: IDashboardState): boolean {
        return state.endDate ? state.endDate < new Date() : false;
      };

      if (this.filter === "active") {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return organization.project.states.some((state) => {
            return isActive(state);
          });
        });
      }

      if (this.filter === "overdue") {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return organization.project.states.some((state) => {
            return isOverDue(state);
          });
        });
      }

      if (this.filter === "activeoverdue") {
        filteredOrgs = this.filteredProjects.filter((organization) => {
          return organization.project.states.some((state) => {
            return isActive(state) || isOverDue(state);
          });
        });
      }

      return filteredOrgs;
    },
    sortedOrganizations: function (): Array<IDashboardRow> {
      const filteredOrgs = this.visibleOrganizations;
      if (this.sortColumn === "project") {
        // Sort the rows by project name
        if (this.sortDirection === "asc") {
          return filteredOrgs.slice().sort((a, b) => {
            if (a.project.name < b.project.name) return -1;
            if (a.project.name > b.project.name) return 1;
            return 0;
          });
        } else {
          return filteredOrgs.slice().sort((a, b) => {
            if (a.project.name > b.project.name) return -1;
            if (a.project.name < b.project.name) return 1;
            return 0;
          });
        }
      } else if (this.sortColumn === "organization") {
        // sort the rows by organization name
        if (this.sortDirection === "asc") {
          return filteredOrgs.slice().sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        } else {
          return filteredOrgs.slice().sort((a, b) => {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
            return 0;
          });
        }
      } else {
        return filteredOrgs;
      }
    },
  },
  watch: {
    searchText: function (newValue, oldValue) {
      this.oldSearchText = oldValue;
    },
    projectType: function () {
      //clear the eixsting organizations and reset pagination info
      this.organizations = [];
      this.endCursor = "";
      this.hasNextPage = false;
      // then fetch the data
      this.fetchData();
    },
    sortDirection: function () {
      // if we haven't loaded all the data yet then fetch from the server
      if (this.hasNextPage) {
        this.freshFetchData();
      }
    },
    sortColumn: function () {
      // if we haven't loaded all the data yet then fetch from the server
      if (this.hasNextPage) {
        this.freshFetchData();
      }
    },
  },
});
